
import { Options, Vue } from 'vue-class-component';
import { BackofficeLocalizationClient, LocalizationClient } from '@/services/Services';
import * as OM from '@/Model';
import BaseServerFilterTable from '@/components/customTable/baseServerFilterTable';
import store from '@/store';
import DictionaryEditModal from '../modal/dictionaryEditModal.vue';

@Options({
    components: {
    }
})
export default class Dictionary extends BaseServerFilterTable {

    list: OM.LocalizedEntity[] = [];
    tableRoot = null;

    init = function(){
        BackofficeLocalizationClient.getAll(this.requestFilter)
        .then(x => {
            this.list = x.items;
            this.totalItems = x.totalItemsCount;
        })
    }

    openLocalizedValueEditModal(item: any) {
        var value = this.list.find( x => x.identifier == item.identifier);

        this.$opModal.show(DictionaryEditModal, {
            localized: value,
            callback: (model: OM.LocalizedEntity) => {
                LocalizationClient.editLocalizedValue(model)
                .then( x => {
                    var found = this.list.find(x => x.identifier == item.identifier);
                    var index = this.list.indexOf(found);

                    store.state.consts.enabledLanguages.forEach( lang => {
                        found.values[lang] = model.values[lang];
                    })
                    
                    this.list[index] = found;

                    this.$opModal.closeLast();
                    this.init();
                }).catch( x => {
                });
            }
        });
    }
}
